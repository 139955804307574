import axios from 'axios'

const instance = axios.create()

instance.defaults.withCredentials = true
instance.defaults.withXSRFToken = true
instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.headers.post['Accept'] = 'application/json'
instance.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = '#/login'
    }

    if (error.response.status >= 405) {
      window.location.href = '#/login'
    }

    if (error.response.status === 404) {
      window.location.href = '#/404'
    }

    if (error.response.status === 500) {
      window.location.href = '#/500'
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  },
)

export default instance
