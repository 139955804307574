import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import axios from './utils/axios'

const container = document.getElementById('root')
const root = createRoot(container)

let authenticated = localStorage.getItem('authenticated')
if (!authenticated) {
  window.location.href = '#/login'
}

axios
  .get(process.env.REACT_APP_API_BASE_URL + `/sanctum/csrf-cookie`)
  .then((response) => {
    if (!response.status === 200 || !response.status === 204) {
      window.location.href = '#/login'
    }
  })
  .catch((error) => {
    window.location.href = '#/login'
  })

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
